<template>
  <div>
    <this-header />
    <!-- <div v-else> -->
    <div class="container-new pb-5">
      <div class="d-flex align-items-center pd2 mb-1">
        <router-link
          v-if="UserData.role
            == 'member'"
          :to="{ name: 'tanghuay-home' }"
        >
          <button
            class="bnt-home"
          >
            <i class="fas fa-home" />
          </button>
        </router-link>
        <div class="txt-right">
          <div class="font-weight-bolder">
            <span class="">รายการหวยล่าสุด</span>
          </div>
        </div>
      </div>
      <b-row class="match-height pd">
        <!-- หวยไทย 1 -->
        <b-col
          v-if="LottoHeadList.Show1.length > 0"
          md="12"
          class="bg-lot mb-1"
        >
          <div>
            <div>
              <h4 class="hd-txt">
                หวยไทย
              </h4>
              <hr>
            </div>
            <div
              class="row gutters-10"
            >
              <div
                v-for="item in LottoHeadList.Show1"
                :key="item.HeadID"
                class="col-md-6 col-lg-4"
              >
                <div
                  class="card-items rainbow2"
                  data-href="/member/lottery/government"
                  @click="buyLotto(item)"
                >
                  <div class="card-img">
                    <img
                      :src="`/huay-logo/${item.LottoPicture}`"
                      style="border-radius: 50%; border: 2px solid #14dcff;"
                    >
                  </div>
                  <div class="card-detail">
                    <div class="font-heading">
                      <div class="font-sub-heading2">
                        <strong>{{ item.LottoName }}</strong>
                      </div>
                      <div class="detail-time">
                        <div class="d-flex align-items-center">
                          <i
                            class="far fa-stopwatch text-info"
                            style="margin-right: 5px;"
                          />
                          <strong
                            class="text-time2 countdown"
                            data-finaldate="1663686600000"
                          >&nbsp;{{ item.CloseDateName ? item.CloseDateName : 'ปิดรับแทง' }} ชม.</strong>
                        </div>
                        <div
                          class="font-sub-title color-sub2"
                        >
                          ปิดรับ : {{ item.CloseDate }} น.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </b-col>

        <!-- หวยหุ้นต่างประเทศ 2 -->
        <b-col
          v-if="LottoHeadList.Show2.length > 0"
          md="12"
          class="bg-lot mb-1"
        >
          <div>
            <div>
              <h4 class="hd-txt">
                หวยหุ้นต่างประเทศ
              </h4>
              <hr>
            </div>
            <div
              class="row gutters-10"
            >
              <div
                v-for="item in LottoHeadList.Show2"
                :key="item.HeadID"
                class="col-md-6 col-lg-4"
              >
                <div
                  class="card-items rainbow"
                  data-href="/member/lottery/government"
                  @click="buyLotto(item)"
                >
                  <div
                    class="card-img"
                  >
                    <img :src="`/huay-logo/${item.LottoPicture}`">
                  </div>
                  <div class="card-detail">
                    <div class="font-heading">
                      <div class="font-sub-heading2">
                        <strong>{{ item.LottoName }}</strong>
                      </div>
                      <div class="detail-time">
                        <div class="d-flex align-items-center">
                          <i
                            class="far fa-stopwatch text-info"
                            style="margin-right: 5px;"
                          />
                          <strong
                            class="text-time2 countdown"
                            data-finaldate="1663686600000"
                          >&nbsp;{{ item.CloseDateName ? item.CloseDateName : 'ปิดรับแทง' }} ชม.</strong>
                        </div>
                        <div
                          class="font-sub-title color-sub2"
                        >
                          ปิดรับ : {{ item.CloseDate }} น.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </b-col>

        <!-- หวยหุ้นไทย 3 -->
        <b-col
          v-if="LottoHeadList.Show3.length > 0"
          md="12"
          class="bg-lot mb-1"
        >
          <div>
            <div>
              <h4 class="hd-txt">
                หวยหุ้นไทย
              </h4>
              <hr>
            </div>
            <div
              class="row gutters-10"
            >
              <div
                v-for="item in LottoHeadList.Show3"
                :key="item.HeadID"
                class="col-md-6 col-lg-4"
              >
                <div
                  class="card-items rainbow2"
                  data-href="/member/lottery/government"
                  @click="buyLotto(item)"
                >
                  <div class="card-img">
                    <img :src="`/huay-logo/${item.LottoPicture}`">
                  </div>
                  <div class="card-detail">
                    <div class="font-heading">
                      <div class="font-sub-heading2">
                        <strong>{{ item.LottoName }}</strong>
                      </div>
                      <div class="detail-time">
                        <div class="d-flex align-items-center">
                          <i
                            class="far fa-stopwatch text-info"
                            style="margin-right: 5px;"
                          />
                          <strong
                            class="text-time2 countdown"
                            data-finaldate="1663686600000"
                          >&nbsp;{{ item.CloseDateName ? item.CloseDateName : 'ปิดรับแทง' }} ชม.</strong>
                        </div>
                        <div
                          class="font-sub-title color-sub2"
                        >
                          ปิดรับ : {{ item.CloseDate }} น.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </b-col>

        <!-- หวยหุ้น VIP 7 -->
        <b-col
          v-if="LottoHeadList.Show7.length > 0"
          md="12"
          class="bg-lot mb-1"
        >
          <div>
            <div>
              <h4 class="hd-txt">
                หวยหุ้น VIP
              </h4>
              <hr>
            </div>
            <div
              class="row gutters-10"
            >
              <div
                v-for="item in LottoHeadList.Show7"
                :key="item.HeadID"
                class="col-md-6 col-lg-4"
              >
                <div
                  class="card-items vip"
                  data-href="/member/lottery/government"
                  @click="buyLotto(item)"
                >
                  <div class="card-img">
                    <img :src="`/huay-logo/${item.LottoPicture}`">
                  </div>
                  <div class="card-detail">
                    <div class="font-heading">
                      <div class="font-sub-heading2">
                        <strong>{{ item.LottoName }}</strong>
                      </div>
                      <div class="detail-time">
                        <div class="d-flex align-items-center">
                          <i
                            class="far fa-stopwatch text-info"
                            style="margin-right: 5px;"
                          />
                          <strong
                            class="text-time2 countdown"
                            data-finaldate="1663686600000"
                          >&nbsp;{{ item.CloseDateName ? item.CloseDateName : 'ปิดรับแทง' }} ชม.</strong>
                        </div>
                        <div
                          class="font-sub-title color-sub2"
                        >
                          ปิดรับ : {{ item.CloseDate }} น.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </b-col>

        <!-- หวยต่างประเทศ 8 -->
        <b-col
          v-if="LottoHeadList.Show8.length > 0"
          md="12"
          class="bg-lot mb-1"
        >
          <div>
            <div>
              <h4 class="hd-txt">
                หวยต่างประเทศ
              </h4>
              <hr>
            </div>
            <div
              class="row gutters-10"
            >
              <div
                v-for="item in LottoHeadList.Show8"
                :key="item.HeadID"
                class="col-md-6 col-lg-4"
              >
                <div
                  class="card-items national"
                  data-href="/member/lottery/government"
                  @click="buyLotto(item)"
                >
                  <div class="card-img">
                    <img :src="`/huay-logo/${item.LottoPicture}`">
                  </div>
                  <div class="card-detail">
                    <div class="font-heading">
                      <div class="font-sub-heading2">
                        <strong>{{ item.LottoName }}</strong>
                      </div>
                      <div class="detail-time">
                        <div class="d-flex align-items-center">
                          <i
                            class="far fa-stopwatch text-info"
                            style="margin-right: 5px;"
                          />
                          <strong
                            class="text-time2 countdown"
                            data-finaldate="1663686600000"
                          >&nbsp;{{ item.CloseDateName ? item.CloseDateName : 'ปิดรับแทง' }} ชม.</strong>
                        </div>
                        <div
                          class="font-sub-title color-sub2"
                        >
                          ปิดรับ : {{ item.CloseDate }} น.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <this-footer />
  </div>
</template>

<script>
import { required, between, digits } from '@validations'
import {
  BRow,
  BCol,
  //   BCard,
  VBToggle,
} from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import momenttz from 'moment-timezone'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ThisHeader from './component/ThisHeader.vue'
import ThisFooter from './component/ThisFooter.vue'

export default {
  components: {
    // BCard,
    BRow,
    BCol,
    ThisHeader,
    ThisFooter,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      isActive: false,
      BetType3: '',
      add_remark: '',
      remark: null,
      discount_member: 0,
      discount: null,
      UserData: JSON.parse(localStorage.getItem('userData')),
      optionsMember: '',
      member: '',
      rule: null,
      confirm: false,
      countArry: 0,
      required,
      between,
      digits,
      payOut: false,
      CheckSubmit: true,
      BugOtp: true,
      show: false,
      ShowLimit: false,
      ShowClose: false,
      fieldsModal: [
        { key: 'type', label: 'ประเภท' },
        { key: 'NumBet', label: 'เลขที่แทง' },
      ],
      // LottoList: JSON.parse(localStorage.getItem('LottoList')),
      LottoList: [],
      LottoHeadList: [],
      LottoSubList: [],
      SumAmount: 0,
      AmountBet: 1,
      NumBet: '',
      NumArrCart: '',
      NumArrCartInput: null,
      otp: '',
      Status: 'Diposit',
      Clicked: 2,
      ShowDigit: 3,
      CheckBetType: true,
      maxlength: 3,
      // BetType: ['3ตัวบน', 'กลับตัวเลข'],
      BetType: [],
      Interval: null,
      IntervalRate: null,
      AgentData: null,
      fields: [
        // { key: 'index', label: 'ลำดับ' },
        { key: 'num_lot', label: 'เลข' },
        { key: 'num_type', label: 'ประเภท' },
        { key: 'price', label: 'ยอด', thStyle: { width: '40%' } },
        { key: 'win_rate', label: 'จ่าย' },
        { key: 'win', label: 'ชนะ' },
        { key: 'delete', label: 'ลบ', thStyle: { width: '5%' } },
      ],
      fieldss: [
        // { key: 'index', label: 'ลำดับ' },
        { key: 'num_lot', label: 'เลข' },
        { key: 'num_type', label: 'ประเภท' },
        { key: 'price', label: 'ยอด', thStyle: { width: '40%' } },
        { key: 'win_rate', label: 'จ่าย' },
        { key: 'win', label: 'ชนะ' },
      ],
      items: [],
      Historyitems: [],
      RateAll: {
        Three: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down_f: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down_b: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Tot: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
        Two: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Tot: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
        One: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
      },
      MinAmount: 0,
      MaxAmount: 0,
      Title: {
        RoundID: '',
        LottoName: '',
        LottoHead: null,
        LottoSubHead: null,
        LottoDetail: [],
        CloseBet: '',
        CloseDate: '',
        CloseDateName: '',
        ShowCloseDate: '',
        LimitU3: [],
        LimitD3: [],
        LimitB3: [],
        LimitT3: [],
        LimitU2: [],
        LimitD2: [],
        LimitT2: [],
        LimitU1: [],
        LimitD1: [],
        CloseBetU3: [],
        CloseBetD3: [],
        CloseBetB3: [],
        CloseBetT3: [],
        CloseBetU2: [],
        CloseBetD2: [],
        CloseBetT2: [],
        CloseBetU1: [],
        CloseBetD1: [],
      },
      CloseBet: {
        CloseU3: [],
        CloseD3: [],
        CloseB3: [],
        CloseT3: [],
        CloseU2: [],
        CloseD2: [],
        CloseT2: [],
        CloseU1: [],
        CloseD1: [],
      },
      CloseBetModal: [],
      Slip: {
        SumAmount: null,
        date: null,
        time: null,
        listID: null,
        count: null,
        LottoHeadName: null,
      },
      statusCheck: false,
      Num_Arr: null,
      userbalance: 0,
      NameShow: '',
    }
  },
  beforeDestroy() {
    clearInterval(this.Interval)
    clearInterval(this.IntervalTime)
  },
  created() {
    this.Interval = setInterval(() => {
      this.ShowWallet()
      this.Getlottolist()
    }, 30000)
  },
  async mounted() {
    // this.getUserslist()
    await this.Getlottolist()
    // this.GetlottolistFix()
    this.ShowAgent()
    this.ShowWallet()
    this.IntervalTime = setInterval(() => {
      this.LoopCloseTime()
    }, 1000)
  },
  methods: {
    buyLotto(data) {
      if (momenttz(data.CloseDate).tz('Asia/Bangkok').valueOf() > momenttz().tz('Asia/Bangkok').valueOf()) {
        // eslint-disable-next-line no-underscore-dangle
        this.$router.push({ path: `/tanghuay-online/${data.LottoName}/${data.LottoHead}/${data.LottoSubHead ? data.LottoSubHead : 0}/${data.CloseDate}/${data._id}` })
      } else {
        this.$swal({
          title: 'ปิดรับแทง',
          text: `ขออภัย ${data.LottoName} ปิดรับแทงแล้ว`,
          icon: 'warning',
          confirmButtonText: 'ตกลง',
        })
      }
    },
    async CheckDiscount(dis) {
      // console.log(dis)
      if (Number(dis) > Number(this.discount)) {
        this.discount_member = this.discount
        this.$swal({
          title: 'แจ้งเตือน',
          text: `ไม่สามารถใช้ส่วนลดได้เกิน ${this.discount} %`,
          icon: 'warning',
          confirmButtonText: 'ตกลง',
        })
      }
    },
    async AddCartInput(num) {
      if (num.length > 3) {
        this.AddCart(num)
      } else if (this.ShowDigit === 3 && num.length === 3) {
        this.NumBet = num
        await this.PushDigit3()
        this.NumArrCartInput = null
        this.setFocusToTextBox()
      } else if (this.ShowDigit === 2 && num.length === 2) {
        this.NumBet = num
        await this.PushDigit2()
        this.NumArrCartInput = null
        this.setFocusToTextBox()
      } else if (this.ShowDigit === 1 && num.length === 1) {
        this.NumBet = num
        await this.PushDigit1()
        this.NumArrCartInput = null
        this.setFocusToTextBox()
      }
    },
    async setFocusToTextBoxCopy() {
      document.getElementById('InputNumCopy').focus()
    },
    async setFocusToTextBox() {
      document.getElementById('InputNum').focus()
    },
    async AddCart(num) {
      this.Num_Arr = null
      this.Num_Arr = num.split(' ')
      // console.log(this.Num_Arr)
      // eslint-disable-next-line no-restricted-syntax
      for await (const item of this.Num_Arr) {
        this.NumBet = item
        if (this.ShowDigit === 3) {
          if (this.NumBet.length === 3) {
            await this.PushDigit3()
            this.setFocusToTextBox()
          }
        } else if (this.ShowDigit === 2) {
          if (this.NumBet.length === 2) {
            await this.PushDigit2()
            this.setFocusToTextBox()
          }
        } else if (this.ShowDigit === 1) {
          if (this.NumBet.length === 1) {
            await this.PushDigit1()
            this.setFocusToTextBox()
          }
        }
      }
      this.NumArrCartInput = null
    },
    async GetDataLotto(check) {
      this.NameShow = check.LottoName
      this.Title.LottoHead = check.LottoHead
      this.Title.LottoSubHead = check.LottoSubHead
      await this.ShowTitle()
      await this.GetMaxMin()
      await this.GetRate(this.Title.RoundID)
    },
    Getlottolist() {
      this.$http
        .post('member/LottoList/RoundListOpen')
        .then(response => {
          this.LottoHeadList = response.data
          // console.log(this.LottoHeadList)
          this.LoopCloseTime()
          // this.LottoSubList = response.data.SubHead
        })
        .catch(error => console.log(error))
    },
    GetlottolistFix() {
      this.$http
        .get('member/LottoList/BuynowFix')
        .then(response => {
          this.LottoList = response.data
        })
        .catch(error => console.log(error))
    },
    getUserslist() {
      this.$http
        .get('member/users/list')
        .then(response => {
          this.show = false
          this.optionsMember = response.data
        })
        .catch(error => console.log(error))
    },
    showRule(data) {
      if (data === 'หวยรัฐบาล') {
        this.rule = 'หวยรัฐบาล'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยออมสิน') {
        this.rule = 'หวยออมสิน'
        this.$refs['my-rule'].show()
      } else if (data === 'หวย ธกส') {
        this.rule = 'หวย ธกส'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (เช้า)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (เที่ยง)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (บ่าย)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (เย็น)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'เยอรมัน') {
        this.rule = 'เยอรมัน'
        this.$refs['my-rule'].show()
      } else if (data === 'อังกฤษ') {
        this.rule = 'อังกฤษ'
        this.$refs['my-rule'].show()
      } else if (data === 'ลาว') {
        this.rule = 'ลาว'
        this.$refs['my-rule'].show()
      } else if (data === 'หุ้นนิเคอิ (เช้า)') {
        this.rule = 'หุ้นนิเคอิ'
        this.$refs['my-rule'].show()
      } else if (data === 'หุ้นนิเคอิ (บ่าย)') {
        this.rule = 'หุ้นนิเคอิ'
        this.$refs['my-rule'].show()
      } else if (data === 'สิงคโปร์') {
        this.rule = 'สิงคโปร์'
        this.$refs['my-rule'].show()
      } else if (data === 'ไต้หวัน') {
        this.rule = 'ไต้หวัน'
        this.$refs['my-rule'].show()
      } else if (data === ' จีน (เช้า)') {
        this.rule = 'จีน'
        this.$refs['my-rule'].show()
      } else if (data === ' จีน (บ่าย)') {
        this.rule = 'จีน'
        this.$refs['my-rule'].show()
      } else if (data === 'อินเดีย') {
        this.rule = 'อินเดีย'
        this.$refs['my-rule'].show()
      } else if (data === 'รัสเซีย') {
        this.rule = 'รัสเซีย'
        this.$refs['my-rule'].show()
      } else if (data === 'ฮานอย') {
        this.rule = 'ฮานอย'
        this.$refs['my-rule'].show()
      } else if (data === 'ฮั่งเส็ง (เช้า)') {
        this.rule = 'ฮั่งเส็ง'
        this.$refs['my-rule'].show()
      } else if (data === 'ฮั่งเส็ง (บ่าย)') {
        this.rule = 'ฮั่งเส็ง'
        this.$refs['my-rule'].show()
      } else if (data === 'มาเลย์') {
        this.rule = 'มาเลย์'
        this.$refs['my-rule'].show()
      } else if (data === 'อียิปต์') {
        this.rule = 'อียิปต์'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยยี่กี') {
        this.rule = 'หวยยี่กี'
        this.$refs['my-rule'].show()
      } else if (data === 'หุ้นเกาหลี') {
        this.rule = 'หุ้นเกาหลี'
        this.$refs['my-rule'].show()
      }
    },
    async ShowAgent() {
      try {
        const { data: response } = await this.$http.get(
          'member/agent/show',
        )
        if (response && response.success === true) {
          this.AgentData = response.AgentData
        }
      } catch (err) {
        console.log(err)
      }
    },
    ClearPriceBase() {
      this.confirm = false
      this.pricebase = null
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= this.countArry - 1; i++) {
        this.items[i].price = 1
      }
    },
    AddPriceBase(val) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= this.countArry - 1; i++) {
        this.items[i].price = val
      }
    },
    provide(item) {
      if (this.candidates.indexOf(item) === -1) {
        this.candidates.push(item)
      } else {
        this.candidates.splice(this.candidates.indexOf(item), 1)
      }
    },
    contains(arr, item) {
      return arr.indexOf(item) !== -1
    },
    btnStates() {
      return this.buttons.map(btn => btn.state)
    },
    // eslint-disable-next-line consistent-return
    async GetSublist() {
      const List = []
      try {
        const { data: Res } = await this.$http.get('https://lottothaiapi.linelotto.vip/api/LottoList')
        // eslint-disable-next-line
                for (const x in Res) {
          let SubList = null
          if (Res[x].Subhead.length > 0) {
            SubList = []
            // eslint-disable-next-line
                        for (const c in Res[x].Subhead) {
              SubList.push(
                { text: Res[x].Subhead[c].name, value: Res[x].Subhead[c].SubHeadID, Data: Res[x].Subhead[c] },
              )
            }
          }
          List.push(
            {
              text: Res[x].name,
              value: Res[x].HeadID,
              SubList,
              Data: Res[x],
            },
          )
        }
        return List
      } catch (e) {
        console.log(e)
      }
    },
    groupBy(list, keyGetter) {
      const map = new Map()
      list.forEach(item => {
        const key = keyGetter(item)
        const collection = map.get(key)
        if (!collection) {
          map.set(key, [item])
        } else {
          collection.push(item)
        }
      })
      return map
    },
    ShowGroupList(Key) {
      if (this.items.length > 0) {
        const grouped = this.groupBy(this.items, group => group.num_type)
        return grouped.get(Key)
      }
      return null
    },
    handleOnComplete() {
      if (this.NumBet.length === this.CalMaxLength()) {
        setTimeout(() => this.CompleteNumBet(), 100)
      }
    },
    Betting(val) {
      if (this.NumBet.length < this.CalMaxLength()) {
        this.NumBet += val
      }
      if (this.NumBet.length === this.CalMaxLength()) {
        setTimeout(() => this.CompleteNumBet(), 200)
        // console.log(this.items)
      }
    },
    showAlertHide() {
      this.$refs['modal-alert'].hide()
    },
    showAlert(CloseBet) {
      this.CloseBetModal = CloseBet
      this.$refs['modal-alert'].show()
    },
    showModal(data) {
      // console.log(data)
      this.Slip.SumAmount = data.Bet.reduce(
        (acc, ele) => acc + Number(ele.amount),
        0,
      )
      this.Slip.date = momenttz(data.Bet[0].created_at).format('DD/MM/YYYY')
      this.Slip.time = momenttz(data.Bet[0].created_at).format('HH:mm:ss')
      this.Slip.count = data.Bet.length
      this.Slip.listID = data.Bet[0].list_id
      this.Slip.LottoHeadName = data.Bet[0].LottoHeadName
      // console.log(this.Slip)
      this.$refs['my-modal'].show()
    },
    async SummitBet() {
      const CheckPrice = this.items.some(el => el.price === '')
      this.$refs.BetItemsRules.validate().then(async success => {
        if (success && CheckPrice === false) {
          this.CheckSubmit = false
          if (
            this.SumAmount > this.userbalance
          ) {
            this.SwalMes(
              'error',
              'จำนวนเงินไม่เพียงพอ !!',
              'ยอดเงินของเมมเบอร์ไม่เพียงพอกรุณาฝากเงินก่อนค่ะ',
            )
            // this.items.splice(this.items.length - 1, 1)
          } else {
            this.show = true
            const Obj = {
              SumAmount: this.SumAmount,
              discount: this.discount,
              UserData: null,
              remark: this.remark,
              OBJ: this.items,
            }
            // console.log(Obj)
            try {
              const { data: response } = await this.$http.post(
                'member/bet/store',
                Obj,
              )
              if (response) {
                this.ShowWallet()
                this.ShowTitle()
                this.GetMaxMin()
                this.GetRate(this.Title.RoundID)
                this.show = false
                this.items = []
                this.CheckSubmit = true
                this.confirm = false
                this.countArry = 0
                this.Slip.SumAmount = null
                this.Slip.date = null
                this.Slip.time = null
                this.Slip.listID = null
                this.Slip.count = null
                this.Slip.LottoHeadName = null
                if (response.success === true) {
                  this.showModal(response)
                  // this.SwalMes(
                  //   'success',
                  //   'ส่งโพยเรียบร้อย',
                  //   `ยอดแทงหวยทั้งหมด ${this.Commas(
                  //     Obj.SumAmount,
                  //   )} บาท ยอดคงเหลือ ${this.Commas(
                  //     response.WalletData.balance,
                  //   )} บาท`,
                  // )
                } else {
                  this.showAlert(response.Mes)
                }
              }
            } catch (err) {
              this.SwalMes('error', 'ส่งโพยไม่สำเร็จ', err.response.data.Mes)
              // console.log(err)
              this.CheckSubmit = true
            }
          }
        }
      })
    },
    async ShowWallet() {
      try {
        const params = {
          UserToken: localStorage.getItem('UserToken'),
        }
        const { data: response } = await this.$http.get(
          'seamless/wallet/show', { params },
        )
        if (response && response.success === true) {
          const Main = response.WalletData
          this.userbalance = Main.balance
          this.discount = response.discount
        } else {
          this.userbalance = 0
        }
      } catch (err) {
        console.log(err)
      }
    },
    async ShowTitle() {
      if (this.Title.LottoDetail.u3 || this.Title.LottoDetail.d3 || this.Title.LottoDetail.b3 || this.Title.LottoDetail.t3) {
        this.ShowDigit = 3
      } else if (this.Title.LottoDetail.u2 || this.Title.LottoDetail.d2 || this.Title.LottoDetail.t2) {
        this.ShowDigit = 2
      } else if (this.Title.LottoDetail.u1 || this.Title.LottoDetail.d1) {
        this.ShowDigit = 1
      }
      const params = {
        LottoHead: this.Title.LottoHead,
        LottoSubHead: this.Title.LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'member/round/ShowByHeadAndSub/Buynow',
          { params },
        )
        if (ResData.success) {
          this.statusCheck = true
          // eslint-disable-next-line no-underscore-dangle
          this.Title.RoundID = ResData.mes._id
          this.Title.CloseBet = ResData.mes.StopBetTime
          this.Title.CloseDate = ResData.mes.CloseDate
          this.Title.CloseDateName = ResData.mes.CloseDateName
          this.Title.LimitU3 = ResData.mes.LimitU3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitD3 = ResData.mes.LimitD3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitB3 = ResData.mes.LimitB3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitT3 = ResData.mes.LimitT3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitU2 = ResData.mes.LimitU2.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitD2 = ResData.mes.LimitD2.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitT2 = ResData.mes.LimitT2.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitU1 = ResData.mes.LimitU1.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitD1 = ResData.mes.LimitD1.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.CloseBetU3 = ResData.mes.CloseBetU3.map(x => x.NumLot)
          this.Title.CloseBetD3 = ResData.mes.CloseBetD3.map(x => x.NumLot)
          this.Title.CloseBetB3 = ResData.mes.CloseBetB3.map(x => x.NumLot)
          this.Title.CloseBetT3 = ResData.mes.CloseBetT3.map(x => x.NumSort)
          this.Title.CloseBetU2 = ResData.mes.CloseBetU2.map(x => x.NumLot)
          this.Title.CloseBetD2 = ResData.mes.CloseBetD2.map(x => x.NumLot)
          this.Title.CloseBetT2 = ResData.mes.CloseBetT2.map(x => x.NumSort)
          this.Title.CloseBetU1 = ResData.mes.CloseBetU1.map(x => x.NumLot)
          this.Title.CloseBetD1 = ResData.mes.CloseBetD1.map(x => x.NumLot)
        }
      } catch (e) {
        this.statusCheck = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'แจ้งเตือน',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: 'ไม่พบรอบที่เปิดรับแทง',
          },
        })
        // console.log(e)
      }
    },
    async GetMaxMin() {
      const params = {
        LottoHead: this.Title.LottoHead,
        LottoSubHead: this.Title.LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'member/rate/ShowByHeadAndSub',
          { params },
        )
        if (ResData.success) {
          this.RateAll.Three.Up.Min = ResData.mes.MinU3
          this.RateAll.Three.Up.Max = ResData.mes.MaxU3
          this.RateAll.Three.Down_f.Min = ResData.mes.MinD3
          this.RateAll.Three.Down_f.Max = ResData.mes.MaxD3
          this.RateAll.Three.Down_b.Min = ResData.mes.MinB3
          this.RateAll.Three.Down_b.Max = ResData.mes.MaxB3
          this.RateAll.Three.Tot.Min = ResData.mes.MinT3
          this.RateAll.Three.Tot.Max = ResData.mes.MaxT3
          this.RateAll.Two.Up.Min = ResData.mes.MinU2
          this.RateAll.Two.Up.Max = ResData.mes.MaxU2
          this.RateAll.Two.Down.Min = ResData.mes.MinD2
          this.RateAll.Two.Down.Max = ResData.mes.MaxD2
          this.RateAll.Two.Tot.Min = ResData.mes.MinT2
          this.RateAll.Two.Tot.Max = ResData.mes.MaxT2
          this.RateAll.One.Up.Min = ResData.mes.MinU1
          this.RateAll.One.Up.Max = ResData.mes.MaxU1
          this.RateAll.One.Down.Min = ResData.mes.MinD1
          this.RateAll.One.Down.Max = ResData.mes.MaxD1
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetRate(RoundID) {
      const params = {
        RoundID,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'member/ratebyround/showbetmore',
          { params },
        )
        if (ResData.success) {
          // console.log(ResData.mes)
          const Rate = ResData.mes.Data
          const ListBet = ResData.mes.LimitData
          this.RateAll.Three.Up.Rate = Rate.U3
          this.RateAll.Three.Up.BetLimit = Rate.BetLimitU3
          this.RateAll.Three.Up.BetList = ListBet.U3
          this.RateAll.Three.Down_f.Rate = Rate.D3
          this.RateAll.Three.Down_f.BetLimit = Rate.BetLimitD3
          this.RateAll.Three.Down_f.BetList = ListBet.D3
          this.RateAll.Three.Down_b.Rate = Rate.B3
          this.RateAll.Three.Down_b.BetLimit = Rate.BetLimitB3
          this.RateAll.Three.Down_b.BetList = ListBet.B3
          this.RateAll.Three.Tot.Rate = Rate.T3
          this.RateAll.Three.Tot.BetLimit = Rate.BetLimitT3
          this.RateAll.Three.Tot.BetList = ListBet.T3
          this.RateAll.Two.Up.Rate = Rate.U2
          this.RateAll.Two.Up.BetLimit = Rate.BetLimitU2
          this.RateAll.Two.Up.BetList = ListBet.U2
          this.RateAll.Two.Down.Rate = Rate.D2
          this.RateAll.Two.Down.BetLimit = Rate.BetLimitD2
          this.RateAll.Two.Down.BetList = ListBet.D2
          this.RateAll.Two.Tot.Rate = Rate.T2
          this.RateAll.Two.Tot.BetLimit = Rate.BetLimitT2
          this.RateAll.Two.Tot.BetList = ListBet.T2
          this.RateAll.One.Up.Rate = Rate.U1
          this.RateAll.One.Up.BetLimit = Rate.BetLimitU1
          this.RateAll.One.Up.BetList = ListBet.U1
          this.RateAll.One.Down.Rate = Rate.D1
          this.RateAll.One.Down.BetLimit = Rate.BetLimitD1
          this.RateAll.One.Down.BetList = ListBet.D1
          // this.CloseBet.CloseU3.push(
          //   ...this.CalCloseBet(ListBet.U3, Rate.BetLimitU3),
          // )
          this.CloseBet.CloseU3 = this.CalCloseBet(
            ListBet.U3,
            this.Title.CloseBetU3,
            Rate.BetLimitU3,
          )
          this.CloseBet.CloseD3 = this.CalCloseBet(
            ListBet.D3,
            this.Title.CloseBetD3,
            Rate.BetLimitD3,
          )
          this.CloseBet.CloseB3 = this.CalCloseBet(
            ListBet.B3,
            this.Title.CloseBetB3,
            Rate.BetLimitB3,
          )
          this.CloseBet.CloseT3 = this.CalCloseBet(
            ListBet.T3,
            this.Title.CloseBetT3,
            Rate.BetLimitT3,
          )
          this.CloseBet.CloseU2 = this.CalCloseBet(
            ListBet.U2,
            this.Title.CloseBetU2,
            Rate.BetLimitU2,
          )
          this.CloseBet.CloseD2 = this.CalCloseBet(
            ListBet.D2,
            this.Title.CloseBetD2,
            Rate.BetLimitD2,
          )
          this.CloseBet.CloseT2 = this.CalCloseBet(
            ListBet.T2,
            this.Title.CloseBetT2,
            Rate.BetLimitT2,
          )
          this.CloseBet.CloseU1 = this.CalCloseBet(
            ListBet.U1,
            this.Title.CloseBetU1,
            Rate.BetLimitU1,
          )
          this.CloseBet.CloseD1 = this.CalCloseBet(
            ListBet.D1,
            this.Title.CloseBetD1,
            Rate.BetLimitD1,
          )

          this.Title.LimitU3 = this.CalLimit(
            this.Title.LimitU3,
            this.CloseBet.CloseU3,
          )
          this.Title.LimitD3 = this.CalLimit(
            this.Title.LimitD3,
            this.CloseBet.CloseD3,
          )
          this.Title.LimitB3 = this.CalLimit(
            this.Title.LimitB3,
            this.CloseBet.CloseB3,
          )
          this.Title.LimitT3 = this.CalLimit(
            this.Title.LimitT3,
            this.CloseBet.CloseT3,
          )
          this.Title.LimitU2 = this.CalLimit(
            this.Title.LimitU2,
            this.CloseBet.CloseU2,
          )
          this.Title.LimitD2 = this.CalLimit(
            this.Title.LimitD2,
            this.CloseBet.CloseD2,
          )
          this.Title.LimitT2 = this.CalLimit(
            this.Title.LimitT2,
            this.CloseBet.CloseT2,
          )
          this.Title.LimitU1 = this.CalLimit(
            this.Title.LimitU1,
            this.CloseBet.CloseU1,
          )
          this.Title.LimitD1 = this.CalLimit(
            this.Title.LimitD1,
            this.CloseBet.CloseD1,
          )
        }
      } catch (e) {
        console.log(e)
      }
    },
    CompleteNumBet() {
      if (this.ShowDigit === 3) {
        this.PushDigit3()
      } else if (this.ShowDigit === 2) {
        this.PushDigit2()
      } else if (this.ShowDigit === 1) {
        this.PushDigit1()
      }
    },
    async PushDigit3() {
      const Swap = this.BetType.indexOf('กลับตัวเลข')
      const UP3 = this.BetType.indexOf('3ตัวบน')
      const DOWN_F3 = this.BetType.indexOf('3ตัวหน้า')
      const DOWN_B3 = this.BetType.indexOf('3ตัวท้าย')
      const TOT = this.BetType.indexOf('3ตัวโต๊ด')
      // eslint-disable-next-line no-nested-ternary
      if (Swap >= 0) {
        if (UP3 >= 0) {
          const Switch3Arr = this.SwitchNum3(this.NumBet)
          await Switch3Arr.forEach(ele => {
            this.PushItems(ele, '3ตัวบน', 3, 'UP', this.AmountBet)
          })
        }
        if (DOWN_F3 >= 0) {
          const Switch3Arr = this.SwitchNum3(this.NumBet)
          await Switch3Arr.forEach(ele => {
            this.PushItems(ele, '3ตัวหน้า', 3, 'DOWN_F', this.AmountBet)
          })
        }
        if (DOWN_B3 >= 0) {
          const Switch3Arr = this.SwitchNum3(this.NumBet)
          await Switch3Arr.forEach(ele => {
            this.PushItems(ele, '3ตัวท้าย', 3, 'DOWN_B', this.AmountBet)
          })
        }
      } else if (TOT >= 0 || UP3 >= 0 || DOWN_F3 >= 0 || DOWN_B3 >= 0) {
        if (TOT >= 0) {
          await this.PushItems(
            this.NumBet,
            '3ตัวโต๊ด',
            3,
            'TOT',
            this.AmountBet,
          )
        }
        if (UP3 >= 0) {
          await this.PushItems(this.NumBet, '3ตัวบน', 3, 'UP', this.AmountBet)
        }
        if (DOWN_F3 >= 0) {
          await this.PushItems(
            this.NumBet,
            '3ตัวหน้า',
            3,
            'DOWN_F',
            this.AmountBet,
          )
        }
        if (DOWN_B3 >= 0) {
          await this.PushItems(
            this.NumBet,
            '3ตัวท้าย',
            3,
            'DOWN_B',
            this.AmountBet,
          )
        }
      }
      this.ClearAfterBet()
      this.PushHistory(this.items)
    },
    async PushDigit2() {
      const Up = this.BetType.indexOf('2ตัวบน') >= 0
      const Down = this.BetType.indexOf('2ตัวล่าง') >= 0
      const Tot = this.BetType.indexOf('2ตัวโต๊ด(บน)') >= 0
      if (this.BetType.indexOf('19ประตู') >= 0) {
        const Res = this.Swipe19(this.NumBet)
        if (Up) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวบน', 2, 'UP', this.AmountBet)
          })
        }
        if (Down) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
          })
        }
      } else if (this.BetType.indexOf('รูดหน้า') >= 0) {
        const Res = this.SwipeFront(this.NumBet)
        if (Up) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวบน', 2, 'UP', this.AmountBet)
          })
        }
        if (Down) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
          })
        }
      } else if (this.BetType.indexOf('รูดหลัง') >= 0) {
        const Res = this.SwipeBack(this.NumBet)
        if (Up) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวบน', 2, 'UP', this.AmountBet)
          })
        }
        if (Down) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
          })
        }
      } else if (this.BetType.indexOf('กลับตัวเลข') >= 0) {
        const Res = this.SwitchNum2(this.NumBet)
        if (Up) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวบน', 2, 'UP', this.AmountBet)
          })
        }
        if (Down) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
          })
        }
      } else {
        if (Up) {
          await this.PushItems(this.NumBet, '2ตัวบน', 2, 'UP', this.AmountBet)
        }
        if (Down) {
          await this.PushItems(this.NumBet, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
        }
        if (Tot) {
          await this.PushItems(this.NumBet, '2ตัวโต๊ด(บน)', 2, 'TOT', this.AmountBet)
        }
      }
      this.ClearAfterBet()
      this.PushHistory(this.items)
    },
    async PushDigit1() {
      const NumType = this.BetType.indexOf('วิ่งบน') >= 0 ? 'วิ่งบน' : 'วิ่งล่าง'
      const NumTypePrefix = this.BetType.indexOf('วิ่งบน') >= 0 ? 'UP' : 'DOWN'

      await this.PushItems(
        this.NumBet,
        NumType,
        1,
        NumTypePrefix,
        this.AmountBet,
      )
      this.ClearAfterBet()
      this.PushHistory(this.items)
    },
    async PushItems(NumBet, NumType, BetDigit, BetType, Price) {
      // console.log(NumBet, NumType, BetDigit, BetType, Price)
      // eslint-disable-next-line no-new
      new Promise((resolve, reject) => {
        const { Rate, RateLimit } = this.CalRate(NumBet, BetDigit, BetType)
        let NumSort = null
        let CheckDup = null
        if (BetType === 'TOT') {
          const NumArr = NumBet.split('')
          NumArr.sort()
          NumSort = NumArr.join('')
          CheckDup = this.items.findIndex(
            obj => obj.NumSort === NumSort
                            && obj.bet_digit === BetDigit
                            && obj.bet_type === BetType,
          )
        } else {
          CheckDup = this.items.findIndex(
            obj => obj.num_lot === NumBet
                            && obj.bet_digit === BetDigit
                            && obj.bet_type === BetType,
          )
        }

        if (CheckDup >= 0) {
          const CheckLimit = this.CheckBetLimit(
            NumBet,
            BetDigit,
            BetType,
            Number(Price) + Number(this.items[CheckDup].price),
          )
          if (CheckLimit.status === true) {
            this.items[CheckDup].price = Number(Price) + Number(this.items[CheckDup].price)
            resolve()
          } else if (CheckLimit.amount <= 0) {
            this.SwalMes('error', 'ปิดรับแทง', `${NumType} หมายเลข ${NumBet}`)
            reject()
          } else {
            this.SwalMes(
              'error',
              'ไม่สำเร็จ !',
              `${NumType} หมายเลข ${NumBet} รับแทงไม่เกิน ${this.Commas(
                CheckLimit.amount,
              )} บาท`,
            )
            reject()
          }
        } else {
          const CheckLimit = this.CheckBetLimit(
            NumBet,
            BetDigit,
            BetType,
            Number(Price),
          )
          if (CheckLimit.status === true) {
            if (RateLimit) {
              this.SwalMes('info', 'หมายเลขจ่ายตาม %', `${NumType} หมายเลข ${NumBet} จ่าย ${Rate} บาท`)
            }
            const ObjItems = {
              num_lot: NumBet,
              num_type: NumType,
              bet_digit: BetDigit, // 1, 2 ,3
              bet_type: BetType, // TOT , UP , DOWN
              price: Price,
              round_id: this.Title.RoundID,
              LottoHead: this.Title.LottoHead,
              LottoSubHead: this.Title.LottoSubHead
                ? this.Title.LottoSubHead
                : null,
              win_rate: Rate,
              NumSort,
              RateLimit,
            }
            this.items.push(ObjItems)
            resolve()
          } else if (CheckLimit.amount === 0) {
            this.SwalMes('error', 'ปิดรับแทง', `${NumType} หมายเลข ${NumBet}`)
            reject()
          } else {
            this.SwalMes(
              'error',
              'ไม่สำเร็จ !',
              `${NumType} หมายเลข ${NumBet} รับแทงไม่เกิน ${this.Commas(
                CheckLimit.amount,
              )} บาท`,
            )
            reject()
          }
        }
      })
    },
    async PushHistory(Val) {
      this.countArry = Val.length
      const St = JSON.stringify(Val)
      this.Historyitems.push(JSON.parse(St))
    },
    CalCloseBet(Arr, CloseList, BetLimit) {
      // console.log(Arr, CloseList, BetLimit)
      let arrArr = Arr.filter(ele => ele.totalAmount >= BetLimit)
      // eslint-disable-next-line no-underscore-dangle
      arrArr = arrArr.map(x => x._id)
      if (CloseList.length > 0) {
        arrArr.push(...CloseList)
      }
      return arrArr.sort()
    },
    CalRate(NumBet, BetDigit, BetType) {
      let Rate = 0
      let IndexLimit = null
      let RateLimit = false
      if (BetDigit === 1) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU1.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU1[IndexLimit].Rate
          } else {
            Rate = this.RateAll.One.Up.Rate
          }
        } else if (BetType === 'DOWN') {
          IndexLimit = this.Title.LimitD1.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD1[IndexLimit].Rate
          } else {
            Rate = this.RateAll.One.Down.Rate
          }
        }
      } else if (BetDigit === 2) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU2.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Up.Rate
          }
        } else if (BetType === 'DOWN') {
          IndexLimit = this.Title.LimitD2.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Down.Rate
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          IndexLimit = this.Title.LimitT3.findIndex(
            ({ NumSort }) => NumSort === SortBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitT2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Tot.Rate
          }
        }
      } else if (BetDigit === 3) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Up.Rate
          }
        } else if (BetType === 'DOWN_F') {
          IndexLimit = this.Title.LimitD3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Down_f.Rate
          }
        } else if (BetType === 'DOWN_B') {
          IndexLimit = this.Title.LimitB3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitB3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Down_b.Rate
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          IndexLimit = this.Title.LimitT3.findIndex(
            ({ NumSort }) => NumSort === SortBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitT3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Tot.Rate
          }
        }
      }
      if (IndexLimit >= 0) {
        RateLimit = true
      }
      return { Rate, RateLimit }
    },
    CalLimit(Limit, CloseBet) {
      return Limit.filter(val => !CloseBet.includes(val.NumLot))
    },
    SelectDigit(Val) {
      this.NumBet = ''
      this.AmountBet = 1
      this.BetType = []
      this.CheckBetType = true
      this.maxlength = Val
      this.ShowDigit = Val
      this.BugOtp = false
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.BugOtp = true), 10)
    },
    ClearAfterBet() {
      this.NumBet = ''
      // this.AmountBet = 1
      // this.BugOtp = false
      // // eslint-disable-next-line no-return-assign
      // setTimeout(() => (this.BugOtp = true), 10)
    },
    SelectBetType3(Val) {
      this.SelectUnDup(this.BetType, Val)
      if (Val === 'กลับตัวเลข') {
        this.CheckRemove(this.BetType, '3ตัวโต๊ด')
      }
      if (Val === '3ตัวโต๊ด') {
        this.CheckRemove(this.BetType, 'กลับตัวเลข')
      }
      this.CheckBetDisable(this.BetType, '3ตัวบน', '3ตัวโต๊ด', '3ตัวหน้า', '3ตัวท้าย')
    },
    async SelectBetType2(Val) {
      // eslint-disable-next-line no-nested-ternary
      const NumType = this.BetType.indexOf('2ตัวบน') >= 0
        ? '2ตัวบน'
        : this.BetType.indexOf('2ตัวล่าง') >= 0
          ? '2ตัวล่าง'
          : '2ตัวโต๊ด(บน)'
      // eslint-disable-next-line no-nested-ternary
      const NumTypePrefix = this.BetType.indexOf('2ตัวบน') >= 0
        ? 'UP'
        : this.BetType.indexOf('2ตัวล่าง') >= 0
          ? 'DOWN'
          : 'TOT'
      if (Val === 'สองตัวคี่') {
        const Res = this.SwipeOdd()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else if (Val === 'สองตัวคู่') {
        const Res = this.SwipeEven()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else if (Val === 'สองตัวสูง') {
        const Res = this.SwipeHigh()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else if (Val === 'สองตัวต่ำ') {
        const Res = this.SwipeLow()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else {
        this.SelectUnDup(this.BetType, Val)
        if (Val === '2ตัวบน') {
          this.maxlength = 2
          // this.CheckRemove(this.BetType, '2ตัวล่าง')
          this.CheckRemove(this.BetType, '2ตัวโต๊ด(บน)')
        } else if (Val === '2ตัวล่าง') {
          this.maxlength = 2
          // this.CheckRemove(this.BetType, '2ตัวบน')
          // this.CheckRemove(this.BetType, '2ตัวโต๊ด(บน)')
        } else if (Val === '2ตัวโต๊ด(บน)') {
          this.maxlength = 2
          this.CheckRemove(this.BetType, '2ตัวบน')
          this.CheckRemove(this.BetType, '2ตัวล่าง')
        } else if (Val === '19ประตู') {
          this.maxlength = 1
          this.CheckRemove(this.BetType, 'รูดหน้า')
          this.CheckRemove(this.BetType, 'รูดหลัง')
          this.CheckRemove(this.BetType, 'กลับตัวเลข')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        } else if (Val === 'รูดหน้า') {
          this.maxlength = 1
          this.CheckRemove(this.BetType, '19ประตู')
          this.CheckRemove(this.BetType, 'รูดหลัง')
          this.CheckRemove(this.BetType, 'กลับตัวเลข')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        } else if (Val === 'รูดหลัง') {
          this.maxlength = 1
          this.CheckRemove(this.BetType, '19ประตู')
          this.CheckRemove(this.BetType, 'รูดหน้า')
          this.CheckRemove(this.BetType, 'กลับตัวเลข')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        } else if (Val === 'กลับตัวเลข') {
          this.CheckRemove(this.BetType, '19ประตู')
          this.CheckRemove(this.BetType, 'รูดหน้า')
          this.CheckRemove(this.BetType, 'รูดหลัง')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        }
        this.CheckBetDisable(this.BetType, '2ตัวบน', '2ตัวล่าง', '2ตัวโต๊ด(บน)')
      }
    },
    SelectBetType1(Val) {
      this.BetType = []
      this.SelectUnDup(this.BetType, Val)
      this.CheckBetDisable(this.BetType, 'วิ่งบน', 'วิ่งล่าง')
    },
    CheckRemove(Arr, Val) {
      const Index = Arr.indexOf(Val)
      if (Index >= 0) {
        Arr.splice(Index, 1)
      }
    },
    SelectUnDup(Arr, Val) {
      const Index = Arr.indexOf(Val)
      if (Index < 0) {
        Arr.push(Val)
        const { Min, Max } = this.CheckAmount(Val)
        if (Max !== 0) {
          this.MinAmount = Min
          this.MaxAmount = Max
        }
      } else {
        Arr.splice(Index, 1)
      }
    },
    CheckBetDisable(Arr, Val1, Val2, Val3, Val4) {
      const Index1 = Arr.indexOf(Val1)
      const Index2 = Arr.indexOf(Val2)
      const Index3 = Arr.indexOf(Val3)
      const Index4 = Arr.indexOf(Val4)
      if (Index1 >= 0 || Index2 >= 0 || Index3 >= 0 || Index4 >= 0) {
        this.CheckBetType = false
      } else {
        this.CheckBetType = true
      }
    },
    TestPrice(Price, Min, Max) {
      if (Price < Min || Price > Max) {
        return true
      }
      return false
    },
    CheckAmount(Val, Price, Index, NumBet = null) {
      const MinMax = { Min: 0, Max: 0 }
      let BetDigit = 3
      let BetType = 'UP'
      if (Val === '3ตัวบน') {
        MinMax.Min = this.RateAll.Three.Up.Min
        MinMax.Max = this.RateAll.Three.Up.Max
      } else if (Val === '3ตัวหน้า') {
        MinMax.Min = this.RateAll.Three.Down_f.Min
        MinMax.Max = this.RateAll.Three.Down_f.Max
        BetType = 'DOWN_F'
      } else if (Val === '3ตัวท้าย') {
        MinMax.Min = this.RateAll.Three.Down_b.Min
        MinMax.Max = this.RateAll.Three.Down_b.Max
        BetType = 'DOWN_B'
      } else if (Val === '3ตัวโต๊ด') {
        MinMax.Min = this.RateAll.Three.Tot.Min
        MinMax.Max = this.RateAll.Three.Tot.Max
        BetType = 'TOT'
      } else if (Val === '2ตัวบน') {
        MinMax.Min = this.RateAll.Two.Up.Min
        MinMax.Max = this.RateAll.Two.Up.Max
        BetDigit = 2
        BetType = 'UP'
      } else if (Val === '2ตัวล่าง') {
        MinMax.Min = this.RateAll.Two.Down.Min
        MinMax.Max = this.RateAll.Two.Down.Max
        BetDigit = 2
        BetType = 'DOWN'
      } else if (Val === '2ตัวโต๊ด(บน)') {
        MinMax.Min = this.RateAll.Two.Tot.Min
        MinMax.Max = this.RateAll.Two.Tot.Max
        BetDigit = 2
        BetType = 'TOT'
      } else if (Val === 'วิ่งบน') {
        MinMax.Min = this.RateAll.One.Up.Min
        MinMax.Max = this.RateAll.One.Up.Max
        BetDigit = 1
        BetType = 'UP'
      } else if (Val === 'วิ่งล่าง') {
        MinMax.Min = this.RateAll.One.Down.Min
        MinMax.Max = this.RateAll.One.Down.Max
        BetDigit = 1
        BetType = 'DOWN'
      }
      if (Price) {
        if (Price < MinMax.Min) {
          this.items[Index].price = MinMax.Min
        } else if (Price > MinMax.Max) {
          this.items[Index].price = MinMax.Max
        }
        const CheckLimit = this.CheckBetLimit(
          NumBet,
          BetDigit,
          BetType,
          Number(Price),
        )
        if (CheckLimit.status === false) {
          this.items[Index].price = CheckLimit.amount > 0 ? CheckLimit.amount : 0
        }
      }
      return MinMax
    },
    CheckBetLimit(NumBet, BetDigit, BetType, Price) {
      let TotalAmount = Price
      let SumAmount = 0
      if (BetDigit === 1) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU1.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.One.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.One.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.One.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN') {
          const CloseIn = this.CloseBet.CloseD1.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.One.Down.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.One.Down.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.One.Down.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      } else if (BetDigit === 2) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU2.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN') {
          const CloseIn = this.CloseBet.CloseD2.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Down.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Down.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Down.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          const CloseIn = this.CloseBet.CloseT2.indexOf(SortBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Tot.BetList.findIndex(
            ({ _id }) => _id === SortBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Tot.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Tot.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      } else if (BetDigit === 3) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN_F') {
          const CloseIn = this.CloseBet.CloseD3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Down_f.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Down_f.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Down_f.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN_B') {
          const CloseIn = this.CloseBet.CloseB3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Down_b.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Down_b.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Down_b.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          const CloseIn = this.CloseBet.CloseT3.indexOf(SortBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Tot.BetList.findIndex(
            ({ _id }) => _id === SortBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Tot.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Tot.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      }
      return { status: true }
    },
    // 3Digit
    SwitchNum3(textnum) {
      const ReArr = []
      const num1 = textnum.substr(0, 1)
      const num2 = textnum.substr(1, 1)
      const num3 = textnum.substr(2, 1)
      if (num1 === num2 || num1 === num3 || num2 === num3) {
        if (num1 === num2) {
          ReArr.push(num1 + num1 + num3, num1 + num3 + num1, num3 + num1 + num1)
        } else if (num1 === num3) {
          ReArr.push(num1 + num2 + num1, num1 + num1 + num2, num2 + num1 + num1)
        } else {
          ReArr.push(num1 + num2 + num2, num2 + num2 + num1, num2 + num1 + num2)
        }
      } else {
        ReArr.push(
          num1 + num2 + num3,
          num1 + num3 + num2,
          num2 + num1 + num3,
          num2 + num3 + num1,
          num3 + num1 + num2,
          num3 + num2 + num1,
        )
      }
      return ReArr
    },
    // 2Digit
    SwitchNum2(textnum) {
      const ReArr = []
      const Val = String(textnum)
      const num1 = Val.substr(0, 1)
      const num2 = Val.substr(1, 1)
      if (num1 === num2) {
        ReArr.push(num1 + num2)
      } else {
        ReArr.push(num1 + num2, num2 + num1)
      }
      return ReArr
    },
    SwipeFront(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = String(Val) + i
        ReArr.push(R1)
      }
      return ReArr
    },
    SwipeBack(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = i + String(Val)
        ReArr.push(R1)
      }
      return ReArr
    },
    Swipe19(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = i + String(Val)
        ReArr.push(R1)
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        if (i === Number(Val)) {
          // eslint-disable-next-line no-plusplus
          i++
        }
        if (i !== 10) {
          const R2 = String(Val) + i
          ReArr.push(R2)
        }
      }
      return ReArr
    },
    SwipeLow() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 50; i++) {
        if (i < 10) {
          ReArr.push(`0${i}`)
        } else {
          ReArr.push(String(i))
        }
      }
      return ReArr
    },
    SwipeHigh() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 50; i < 100; i++) {
        if (i < 10) {
          ReArr.push(`0${i}`)
        } else {
          ReArr.push(String(i))
        }
      }
      return ReArr
    },
    SwipeOdd() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 100; i++) {
        if (i % 2) {
          if (i < 10) {
            ReArr.push(`0${i}`)
          } else {
            ReArr.push(String(i))
          }
        }
      }
      return ReArr
    },
    SwipeEven() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 100; i++) {
        if (i % 2 !== 1) {
          if (i < 10) {
            ReArr.push(`0${i}`)
          } else {
            ReArr.push(String(i))
          }
        }
      }
      return ReArr
    },
    Undo() {
      this.items = []
      this.Historyitems = []
      this.countArry = 0
      // if (this.Historyitems.length > 1) {
      //   this.Historyitems.pop()
      //   this.items = this.Historyitems[this.Historyitems.length - 1]
      // } else {
      //   this.items = []
      //   this.Historyitems = []
      // }
    },
    CalMaxLength() {
      const CheckLengthType = this.BetType.findIndex(
        obj => obj === '19ประตู' || obj === 'รูดหน้า' || obj === 'รูดหลัง',
      )
      if (CheckLengthType >= 0) {
        this.maxlength = 1
      } else {
        this.maxlength = this.ShowDigit
      }
      return this.maxlength
    },
    CalSumAmount() {
      const SumAmount = this.items.reduce(
        (acc, ele) => acc + Number(ele.price),
        0,
      )
      if (SumAmount > this.userbalance) {
        if (this.CheckSubmit) {
          this.SwalMes(
            'error',
            'จำนวนเงินไม่ถูกต้อง !',
            'ยอดเงินของคุณไม่เพียงพอกรุณาลองใหม่อีกครั้งค่ะ',
          )
          this.CheckSubmit = !this.CheckSubmit
        }
      } else {
        this.CheckSubmit = true
      }
      if (this.discount_member > 0) {
        this.SumAmount = SumAmount - ((this.discount_member * SumAmount) / 100)
        return this.SumAmount
      }
      this.SumAmount = SumAmount
      return this.SumAmount
    },
    LoopCloseTime() {
      // eslint-disable-next-line
      for (const K2 in this.LottoHeadList.Show1) {
        const Item = this.LottoHeadList.Show1[K2]
        Item.CloseDateName = this.CalDiffTime(Item.CloseDate)
      }
      // eslint-disable-next-line
      for (const K2 in this.LottoHeadList.Show2) {
        const Item = this.LottoHeadList.Show2[K2]
        Item.CloseDateName = this.CalDiffTime(Item.CloseDate)
      }
      // eslint-disable-next-line
      for (const K2 in this.LottoHeadList.Show3) {
        const Item = this.LottoHeadList.Show3[K2]
        Item.CloseDateName = this.CalDiffTime(Item.CloseDate)
      }
      // eslint-disable-next-line
      for (const K2 in this.LottoHeadList.Show4) {
        const Item = this.LottoHeadList.Show4[K2]
        Item.CloseDateName = this.CalDiffTime(Item.CloseDate)
      }
      // eslint-disable-next-line
      for (const K2 in this.LottoHeadList.Show5) {
        const Item = this.LottoHeadList.Show5[K2]
        Item.CloseDateName = this.CalDiffTime(Item.CloseDate)
      }
      // eslint-disable-next-line
      for (const K2 in this.LottoHeadList.Show6) {
        const Item = this.LottoHeadList.Show6[K2]
        Item.CloseDateName = this.CalDiffTime(Item.CloseDate)
      }
      // eslint-disable-next-line
      for (const K2 in this.LottoHeadList.Show7) {
        const Item = this.LottoHeadList.Show7[K2]
        Item.CloseDateName = this.CalDiffTime(Item.CloseDate)
      }
      // eslint-disable-next-line
      for (const K2 in this.LottoHeadList.Show8) {
        const Item = this.LottoHeadList.Show8[K2]
        Item.CloseDateName = this.CalDiffTime(Item.CloseDate)
      }
    },
    // eslint-disable-next-line consistent-return
    CalDiffTime(Val) {
      const DateRound = momenttz(Val).tz('Asia/Bangkok')
      const DateNow = momenttz().tz('Asia/Bangkok')
      const Seconds = DateRound.diff(DateNow, 'seconds')

      const Day = Math.floor(Seconds / 86400)
      const Hours = Math.floor((Seconds % 86400) / 3600)
      const Minutes = Math.floor((Seconds % 3600) / 60)
      const numseconds = Math.floor((Seconds % 3600) % 60)

      if (Day >= 0) {
        return `${Day} วัน ${Hours < 10 ? `0${Hours}` : Hours} : ${Minutes < 10 ? `0${Minutes}` : Minutes
        } : ${numseconds < 10 ? `0${numseconds}` : numseconds}`
      }
      clearInterval(this.Interval)
      this.$router.push({ name: 'lottery' })
    },
    Commas(x) {
      if (x) {
        if (Math.round(x) !== x && x > 0) {
          const Num = Number(x)
          const y = Num.toFixed(2)
          return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
    SwalMes(Type, Title, Html) {
      this.$swal({
        icon: Type,
        title: Title,
        html: Html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    // Modal
    // showModal() {
    //   this.$refs['my-modal'].show()
    // },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
  },
}
</script>

<style scoped>
.flex-fill {
    padding: 1px;
}

.view-rules {
    display: inline-flex;
    align-items: center;
    border: 0;
    text-align: center;
    background-color: #FCC201;
    border-radius: 13px;
    font-size: 12px;
    font-weight: 700;
    padding: 5px 15px;

}

td {
    border: 1px solid #707070af;
    background: #fff;
    padding: 5px;
}

tr {
    margin: auto;
    width: 320px;
}

.form-css {
    border: none !important;
    border-radius: 10px !important;
}

.btns-bet-l {
    width: calc(100% - 110px);
}

.row-top-action {
    background-color: rgb(93, 173, 240) !important;
    /* background-image: url(/back_bg.svg) !important; */
}

.row-bottom-action {
    background-color: #0c2574 !important;
    /* background-image: url(/back_bg.svg) !important; */
}

@media only screen and (max-width: 768px) {
    .row-bottom-action {
        width: 100% !important;
        left: 0;
        bottom: 0;
    }
}

@media (max-width: 768px) {
    .bet-action-bottom .bet-detail {
        width: 50%;
        border-bottom: 1px solid #7f0000;
    }
}

.bet-action-bottom .bet-detail {
    min-width: 140px;
    padding: 10px 20px;
    border-right: 1px solid #7f0000;
}

.input-bottom-bet {
    width: calc(100% - 90px);
    height: 44px;
    padding: 10px 15px;
    font-size: 20px;
    font-weight: 700;
    color: #FF6700;
    border: none;
    border-radius: 4px;
}

@media (max-width: 768px) {

    .bet-action-bottom .bet-button,
    .bet-action-bottom .bet-form {
        margin: 0 auto;
        width: 100%;
    }
}

.bet-button {
    padding: 5px 20px 15px;
    width: 360px;
    display: flex;
}

.float-left {
    float: left !important;
}

a:focus,
input {
    outline: 0;
}

.color-sub {
    color: #fff;
}

.color-white {
    color: #FFF;
}

.font-page-title {
    font-size: 20px;
}

.bet-list-item.yellow {
    color: #edad0c;
}

.bet-list-item.orange {
    color: #FF6700;
}

.bet-list-item.green {
    color: #2CA468;
}

.bet-list-item.yellow.selected,
.bet-list-item.yellow.selected:hover {
    background-color: #FCC201;
    color: #FFF;
}

.bet-list-item {
    float: left;
    width: 100%;
    border-radius: 15px;
    background-color: #FFF;
    line-height: 20px;
    color: #818181;
    font-size: 12px;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, .125);
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
}

.font-page-title {
    font-size: 20px;
}

.bet-sm-item {
    width: 100%;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    color: #818181;
    text-align: center;
    background-color: #FFF;
    display: block;
}

.btn1.active {
    background: #3b5898 !important;
    color: #fff !important;
}

.button-num-cal {
    width: 100%;
    background: linear-gradient(180deg, #FFF 0, #F1F1F1 100%);
    border: none;
    margin: 0 0 10px;
    font-size: 30px;
}

.button-num-cals {
    width: 100%;
    background: linear-gradient(180deg, #424242 0, #202020 100%);
    border: none;
    font-size: 16px;
    color: #fff;
    border-radius: 4px;
    margin: 0 0 10px;
    font-size: 30px;
}

.button-num-calss {
    width: 100%;
    background: linear-gradient(180deg, #424242 0, #202020 100%);
    border: none;
    font-size: 16px;
    border-radius: 4px;
    color: #fff;
    margin: 0 0 10px;
    font-size: 30px;
}

.button-action-cal,
.button-num-cal {
    box-shadow: 1px 1px 2px rgb(0 0 0 / 25%);
    border-radius: 4px;
    text-align: center;
    height: 45px;
}

.clicked {
    background: #3b5898 !important;
    color: #fff !important;
}

.btns,
.input-2-box .text-control {
    font-weight: 700;
    padding: 10px;
    line-height: 24px;
}

.bg-cradit {
    margin: auto;
    background-color: #fff;
    color: #000;
    width: 150px;
    font-size: 0.6rem;
    border-radius: 10px;
}

.condition-top {
    background-color: #d40000;
    border-radius: 10px 10px 0px 0px;
    padding: 5px;
    color: #fff;
}

.condition-body {
    background-color: #838383;
    border-radius: 0px 0px 10px 10px;
    color: #fff;
}

.btn-type3 {
    width: 100%;
    color: #707070;
    background-color: rgba(0, 0, 0, 0);
    padding: 10px 2px;
    /* border-radius: 25px; */
    font-weight: 700;
    border: 0px;
}

.btn-type2 {
    width: 100%;
    color: #707070;
    background-color: rgba(0, 0, 0, 0);
    padding: 10px 2px;
    font-weight: 700;
    /* border-radius: 25px; */
    border: 0px;
}

.btn-type1 {
    width: 100%;
    color: #707070;
    background-color: rgba(0, 0, 0, 0);
    padding: 10px 2px;
    font-weight: 700;
    /* border-radius: 25px; */
    border: 0px;
}

.active,
.btnp:focus {
    border-bottom: 4px solid #0c2574;
    color: #0c2574;
}

.header-bg-content2 {
    background-color: #fbc02d;
    border-bottom: 2px solid #0c2574;
}

.bg-side {
    background-color: #111;
    border-radius: 5px;
    padding: 2px;
    color: #fff;
}

.pdd {
    margin: auto;
    max-width: 540px;
}

hr {
    margin-top: -2px;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right,
            rgba(0, 0, 0, 0),
            rgba(221, 221, 221, 0.75),
            rgba(0, 0, 0, 0));
}

p {
    line-height: 0.7;
}

.bg-danger {
    background-color: #dc3545 !important;
}

.text-white {
    color: rgb(0, 115, 160) !important;
}

@media screen and (max-width: 768px) {
    .btn-tanghuay {
        font-size: 12px !important;
        padding-right: 4px;
    }
}

.btn-group-sm>.btn,
.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.btn-tanghuay {
    line-height: 1;
}

.action-bar.bar-white .nav-left {
    color: rgb(0, 0, 0) !important;
    font-weight: 700;
}

.action-bar.bar-white .nav-right {
    color: rgb(0, 0, 0) !important;
}
</style>

<style lang="scss" scoped>
@import url(https://unpkg.com/@8bu/vue-otp-input@1.1.6/dist/vue-otp-input.css);
@import '@core/scss/vue/pages/page-auth.scss';

.form-control {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0rem;
}

.style-1 {
    .otp-input-8-field input {
        border-radius: 5;
        border: 2px solid #c50000;

        &:not(:last-child) {
            margin-right: 0.5rem;
        }
    }
}

.col-4,
.col-sm-6 {
    padding: 0.18rem;
    max-width: 100%;
    flex-grow: 1;
}

</style>
